<template>
  <div id="order">
    <tmpl_header :hide="['nav']" />

    <div class="container" v-if="order">
      <div class="breadcrumb">
        <ul>
          <li class="r">
            <router-link to="/center/orders">我的订单</router-link>
          </li>
          <li>
            <span>订单详情</span>
          </li>
        </ul>
      </div>

      <section class="progress card">
        <div class="info">
          <h4>订单编号：{{ order.orderNo }}</h4>
          <strong>{{ orderStatus(order.orderStatus) }}</strong>
          <div class="operate">待定</div>
        </div>
        <ul>
          <li class="create act">
            <strong>提交订单</strong>
            <span>{{ order.addTime }}</span>
          </li>
          <li class="pay" :class="{ act: order.payTime }">
            <strong>订单支付</strong>
            <span>{{ order.payTime }}</span>
          </li>
          <li class="ship" :class="{ act: order.shippingTime }">
            <strong>商品出库</strong>
            <span>{{ order.shippingTime }}</span>
          </li>
          <li class="receiving" :class="{ act: order.getGoodsTime }">
            <strong>已收货</strong>
            <span>{{ order.getGoodsTime }}</span>
          </li>
          <li class="complete" :class="{ act: order.successTime }">
            <strong>完成</strong>
            <span>{{ order.successTime }}</span>
          </li>
        </ul>
      </section>

      <section class="card products">
        <div class="list">
          <ul>
            <li class="thead">
              <div class="name">商品信息</div>
              <div class="attrs">规格</div>
              <div class="price">单价(元)</div>
              <div class="num">数量</div>
              <div class="total">合计</div>
            </li>
            <li class="tr" v-for="pro in order.goods" :key="pro.goodsId">
              <div class="name">
                <router-link :to="'/goods/view/' + pro.goodsId">
                  <img :src="pro.goodsImg" />
                  <p>{{ pro.goodsName }}</p>
                </router-link>
              </div>
              <div class="attrs">--</div>
              <div class="price">¥{{ formatPrice(pro.goodsPrice) }}</div>
              <div class="num">{{ pro.goodsNum }}</div>
              <div class="total">¥{{ formatPrice(pro.goodsInfoSumPrice) }}</div>
            </li>
          </ul>
        </div>
      </section>

      <section class="card others">
        <div class="ship" v-if="order.express">
          <h3>配送信息</h3>
          <div>
            <p>
              <label>配送方式</label><span>{{ order.expressName }}</span>
            </p>
            <p>
              <label>运费</label><span>¥{{ order.shippingFee }}</span>
            </p>
          </div>
        </div>
        <div class="address">
          <h3>收货信息</h3>
          <div>
            <p>
              <label>收货人</label><span>{{ order.shippingPerson }}</span>
            </p>
            <p>
              <label>地址</label
              ><span
                >{{ order.shippingProvince }} {{ order.shippingCity }}
                {{ order.shippingCounty }} {{ order.shippingAddress }}
              </span>
            </p>
            <p>
              <label>手机号</label><span>{{ order.shippingMobile }}</span>
            </p>
          </div>
        </div>
        <div class="pay" v-if="order">
          <h3>付款信息</h3>
          <div>
            <p>
              <label>付款方式</label
              ><span v-if="order.payType == '9'">微信支付</span
              ><span v-else-if="order.payType == '7'">支付宝</span
              ><span v-else>未支付</span>
            </p>
            <p>
              <label>商品总额</label
              ><span>¥{{ formatPrice(order.oldPrice) }}</span>
            </p>
            <p>
              <label>运费</label
              ><span>¥{{ formatPrice(order.shippingFee) }}</span>
            </p>
            <p class="total" v-if="order.payTime">
              <label>实付款</label
              ><span>¥{{ formatPrice(order.moneyPaid) }}</span>
            </p>
          </div>
        </div>
      </section>
    </div>

    <tmpl_footer />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import api from "../../api/index";
import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,

      orderId: 0,
      order: null,
      status: "",
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  watch: {},

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/order") },
      });

    this.orderId = this.$route.query.order;

    api.all([this.loadOrder()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadOrder: function () {
      return api
        .post(apis.order.detail, {
          orderId: this.orderId,
        })
        .then((res) => {
          this.order = res;
        });
    },

    formatPrice(price) {
      let fen = (price * 100) % 100;
      return Math.floor(price) + "." + (fen < 10 ? "0" : "") + fen;
    },

    orderStatus(status) {
      return apis.orderStatus(status);
    },

    orderOperates(status) {
      return apis.orderOperates(status);
    },
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./order.less");
</style>
